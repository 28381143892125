@font-face {
  font-family: 'p6-icon-font';
  src:  url('p6-icon-font/p6-icon-font.eot?awu041');
  src:  url('p6-icon-font/p6-icon-font.eot?awu041#iefix') format('embedded-opentype'),
    url('p6-icon-font/p6-icon-font.woff2?awu041') format('woff2'),
    url('p6-icon-font/p6-icon-font.ttf?awu041') format('truetype'),
    url('p6-icon-font/p6-icon-font.woff?awu041') format('woff'),
    url('p6-icon-font/p6-icon-font.svg?awu041#p6-icon-font') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="p6-icon-"], [class*=" p6-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'p6-icon-font' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.p6-icon-hand-right:before {
  content: "\e9ff";
}
.p6-icon-hand-left:before {
  content: "\ea00";
}
.p6-icon-exploration:before {
  content: "\ea01";
}
.p6-icon-stabilo:before {
  content: "\ea02";
}
.p6-icon-chain:before {
  content: "\ea03";
}
.p6-icon-breadcrumb:before {
  content: "\ea04";
}
.p6-icon-gift:before {
  content: "\ea05";
}
.p6-icon-streak-record:before {
  content: "\ea06";
}
.p6-icon-banana:before {
  content: "\ea07";
}
.p6-icon-weekly-learned:before {
  content: "\ea08";
}
.p6-icon-user-average:before {
  content: "\ea09";
}
.p6-icon-storage:before {
  content: "\ea0a";
}
.p6-icon-new:before {
  content: "\ea0b";
}
.p6-icon-dataprotection-family:before {
  content: "\ea0c";
}
.p6-icon-grammar:before {
  content: "\e9fe";
}
.p6-icon-plant-2:before {
  content: "\e9fd";
}
.p6-icon-licence:before {
  content: "\e9fc";
}
.p6-icon-checkbox:before {
  content: "\e919";
}
.p6-icon-move-to-start:before {
  content: "\e9b8";
}
.p6-icon-move-to-end:before {
  content: "\e9ba";
}
.p6-icon-discount-group:before {
  content: "\e929";
}
.p6-icon-test:before {
  content: "\e935";
}
.p6-icon-aea:before {
  content: "\e936";
}
.p6-icon-camera:before {
  content: "\e937";
}
.p6-icon-reprofile:before {
  content: "\e938";
}
.p6-icon-no-internet:before {
  content: "\e939";
}
.p6-icon-heart:before {
  content: "\e93a";
}
.p6-icon-file:before {
  content: "\e93b";
}
.p6-icon-pic:before {
  content: "\e93c";
}
.p6-icon-sleep:before {
  content: "\e93d";
}
.p6-icon-thumb-up:before {
  content: "\e93e";
}
.p6-icon-finish:before {
  content: "\e93f";
}
.p6-icon-vocabulary-list-unset:before {
  content: "\e940";
}
.p6-icon-calendar-edit:before {
  content: "\e941";
}
.p6-icon-chevron-right:before {
  content: "\e942";
}
.p6-icon-eye-closed:before {
  content: "\e943";
}
.p6-icon-pin-off:before {
  content: "\e944";
}
.p6-icon-task:before {
  content: "\e945";
}
.p6-icon-challenge:before {
  content: "\e946";
}
.p6-icon-challenge-award:before {
  content: "\e947";
}
.p6-icon-chevron-left:before {
  content: "\e948";
}
.p6-icon-discount:before {
  content: "\e949";
}
.p6-icon-feedback-2:before {
  content: "\e94a";
}
.p6-icon-stuff:before {
  content: "\e94b";
}
.p6-icon-download:before {
  content: "\e94c";
}
.p6-icon-duplicate:before {
  content: "\e94d";
}
.p6-icon-limit:before {
  content: "\e94e";
}
.p6-icon-switch:before {
  content: "\e94f";
}
.p6-icon-switch-set:before {
  content: "\e950";
}
.p6-icon-cards-2:before {
  content: "\e951";
}
.p6-icon-compass:before {
  content: "\e952";
}
.p6-icon-cube:before {
  content: "\e953";
}
.p6-icon-exercise-failed:before {
  content: "\e954";
}
.p6-icon-flowchart:before {
  content: "\e955";
}
.p6-icon-genius:before {
  content: "\e956";
}
.p6-icon-translate-set:before {
  content: "\e957";
}
.p6-icon-exercise-ok:before {
  content: "\e958";
}
.p6-icon-sent:before {
  content: "\e959";
}
.p6-icon-undone:before {
  content: "\e95a";
}
.p6-icon-avatar:before {
  content: "\e95b";
}
.p6-icon-logout-Kopie-35:before {
  content: "\e95c";
}
.p6-icon-logout-Kopie-36:before {
  content: "\e95d";
}
.p6-icon-stop-watch:before {
  content: "\e95e";
}
.p6-icon-in-10-false:before {
  content: "\e95f";
}
.p6-icon-data-privacy-best:before {
  content: "\e960";
}
.p6-icon-edit-avatar:before {
  content: "\e961";
}
.p6-icon-logout-Kopie-32:before {
  content: "\e962";
}
.p6-icon-my-school:before {
  content: "\e963";
}
.p6-icon-my-school-set:before {
  content: "\e964";
}
.p6-icon-school_building_2:before {
  content: "\e965";
}
.p6-icon-share-ios:before {
  content: "\e966";
}
.p6-icon-school_building:before {
  content: "\e967";
}
.p6-icon-star-full:before {
  content: "\e968";
}
.p6-icon-first-steps:before {
  content: "\e969";
}
.p6-icon-flag:before {
  content: "\e96a";
}
.p6-icon-leaderboard:before {
  content: "\e96b";
}
.p6-icon-placement:before {
  content: "\e96c";
}
.p6-icon-achievement-task:before {
  content: "\e96d";
}
.p6-icon-darkmode:before {
  content: "\e96e";
}
.p6-icon-participants:before {
  content: "\e96f";
}
.p6-icon-phase-up-down:before {
  content: "\e970";
}
.p6-icon-data-protection-awaiting:before {
  content: "\e971";
}
.p6-icon-data-protection-on:before {
  content: "\e972";
}
.p6-icon-annotation-alt:before {
  content: "\e973";
}
.p6-icon-data-protection-none:before {
  content: "\e974";
}
.p6-icon-child-unlock:before {
  content: "\e975";
}
.p6-icon-child-lock:before {
  content: "\e976";
}
.p6-icon-correct:before {
  content: "\e977";
}
.p6-icon-cup-learned:before {
  content: "\e978";
}
.p6-icon-wrong:before {
  content: "\e979";
}
.p6-icon-context-menu:before {
  content: "\e97a";
}
.p6-icon-special-character:before {
  content: "\e97b";
}
.p6-icon-arrow-both:before {
  content: "\e97c";
}
.p6-icon-leaderboard-nav:before {
  content: "\e97d";
}
.p6-icon-arrow-back-mobile:before {
  content: "\e97e";
}
.p6-icon-calendar-day:before {
  content: "\e97f";
}
.p6-icon-settings-alt:before {
  content: "\e980";
}
.p6-icon-search-book:before {
  content: "\e981";
}
.p6-icon-star-minus:before {
  content: "\e982";
}
.p6-icon-star-plus:before {
  content: "\e983";
}
.p6-icon-documents:before {
  content: "\e984";
}
.p6-icon-unlock:before {
  content: "\e985";
}
.p6-icon-reset-alt:before {
  content: "\e986";
}
.p6-icon-add-to-library:before {
  content: "\e987";
}
.p6-icon-menu:before {
  content: "\e988";
}
.p6-icon-activation:before {
  content: "\e989";
}
.p6-icon-rocket:before {
  content: "\e98a";
}
.p6-icon-appearance:before {
  content: "\e98b";
}
.p6-icon-one-star:before {
  content: "\e98c";
}
.p6-icon-refresh:before {
  content: "\e98d";
}
.p6-icon-three-stars:before {
  content: "\e98e";
}
.p6-icon-two-stars:before {
  content: "\e98f";
}
.p6-icon-keys:before {
  content: "\e990";
}
.p6-icon-keys-off:before {
  content: "\e991";
}
.p6-icon-paint:before {
  content: "\e992";
}
.p6-icon-running-time:before {
  content: "\e993";
}
.p6-icon-no-star:before {
  content: "\e994";
}
.p6-icon-reports-add-user:before {
  content: "\e995";
}
.p6-icon-reports-family:before {
  content: "\e996";
}
.p6-icon-reports-group:before {
  content: "\e997";
}
.p6-icon-teacher:before {
  content: "\e998";
}
.p6-icon-folder:before {
  content: "\e999";
}
.p6-icon-game:before {
  content: "\e99a";
}
.p6-icon-games:before {
  content: "\e99b";
}
.p6-icon-globe:before {
  content: "\e99c";
}
.p6-icon-google:before {
  content: "\e99d";
}
.p6-icon-group:before {
  content: "\e99e";
}
.p6-icon-hand:before {
  content: "\e99f";
}
.p6-icon-help:before {
  content: "\e9a0";
}
.p6-icon-history:before {
  content: "\e9a1";
}
.p6-icon-house:before {
  content: "\e9a2";
}
.p6-icon-image:before {
  content: "\e9a3";
}
.p6-icon-images:before {
  content: "\e9a4";
}
.p6-icon-imprint:before {
  content: "\e9a5";
}
.p6-icon-info:before {
  content: "\e9a6";
}
.p6-icon-in-progress:before {
  content: "\e9a7";
}
.p6-icon-in-use:before {
  content: "\e9a8";
}
.p6-icon-italic:before {
  content: "\e9a9";
}
.p6-icon-keyboard:before {
  content: "\e9aa";
}
.p6-icon-keyboard-off:before {
  content: "\e9ab";
}
.p6-icon-key-hide:before {
  content: "\e9ac";
}
.p6-icon-key-show:before {
  content: "\e9ad";
}
.p6-icon-leaderboards:before {
  content: "\e9ae";
}
.p6-icon-learn:before {
  content: "\e9af";
}
.p6-icon-learning-progress:before {
  content: "\e9b0";
}
.p6-icon-library:before {
  content: "\e9b1";
}
.p6-icon-lock:before {
  content: "\e9b2";
}
.p6-icon-login:before {
  content: "\e9b3";
}
.p6-icon-logout:before {
  content: "\e9b4";
}
.p6-icon-magic-wand:before {
  content: "\e9b5";
}
.p6-icon-mail:before {
  content: "\e9b6";
}
.p6-icon-mic:before {
  content: "\e9b7";
}
.p6-icon-move-to-end-alt:before {
  content: "\e9b9";
}
.p6-icon-move-to-start-alt:before {
  content: "\e9bb";
}
.p6-icon-no-internet1:before {
  content: "\e9bc";
}
.p6-icon-note:before {
  content: "\e9bd";
}
.p6-icon-notification:before {
  content: "\e9be";
}
.p6-icon-overview:before {
  content: "\e9bf";
}
.p6-icon-p6:before {
  content: "\e9c0";
}
.p6-icon-parent:before {
  content: "\e9c1";
}
.p6-icon-parent-avatar:before {
  content: "\e9c2";
}
.p6-icon-paused:before {
  content: "\e9c3";
}
.p6-icon-phase-system:before {
  content: "\e9c4";
}
.p6-icon-pin:before {
  content: "\e9c5";
}
.p6-icon-play:before {
  content: "\e9c6";
}
.p6-icon-plus:before {
  content: "\e9c7";
}
.p6-icon-position:before {
  content: "\e9c8";
}
.p6-icon-preview:before {
  content: "\e9c9";
}
.p6-icon-qr-scan:before {
  content: "\e9ca";
}
.p6-icon-random:before {
  content: "\e9cb";
}
.p6-icon-reports:before {
  content: "\e9cc";
}
.p6-icon-reprofiling:before {
  content: "\e9cd";
}
.p6-icon-reset:before {
  content: "\e9ce";
}
.p6-icon-rotate-left:before {
  content: "\e9cf";
}
.p6-icon-rotate-right:before {
  content: "\e9d0";
}
.p6-icon-scan:before {
  content: "\e9d1";
}
.p6-icon-school:before {
  content: "\e9d2";
}
.p6-icon-school-building:before {
  content: "\e9d3";
}
.p6-icon-school-building-old:before {
  content: "\e9d4";
}
.p6-icon-school-cap:before {
  content: "\e9d5";
}
.p6-icon-search:before {
  content: "\e9d6";
}
.p6-icon-select-all:before {
  content: "\e9d7";
}
.p6-icon-send:before {
  content: "\e9d8";
}
.p6-icon-settings:before {
  content: "\e9d9";
}
.p6-icon-share:before {
  content: "\e9da";
}
.p6-icon-skip:before {
  content: "\e9db";
}
.p6-icon-sliders:before {
  content: "\e9dc";
}
.p6-icon-smiley-bad:before {
  content: "\e9dd";
}
.p6-icon-smiley-good:before {
  content: "\e9de";
}
.p6-icon-speaker:before {
  content: "\e9df";
}
.p6-icon-speech:before {
  content: "\e9e0";
}
.p6-icon-speech-error:before {
  content: "\e9e1";
}
.p6-icon-stock:before {
  content: "\e9e2";
}
.p6-icon-stop:before {
  content: "\e9e3";
}
.p6-icon-streak:before {
  content: "\e9e4";
}
.p6-icon-support:before {
  content: "\e9e5";
}
.p6-icon-sync:before {
  content: "\e9e6";
}
.p6-icon-target:before {
  content: "\e9e7";
}
.p6-icon-thumbs-down:before {
  content: "\e9e8";
}
.p6-icon-thumbs-up:before {
  content: "\e9e9";
}
.p6-icon-translate:before {
  content: "\e9ea";
}
.p6-icon-trash:before {
  content: "\e9eb";
}
.p6-icon-unconfirmed:before {
  content: "\e9ec";
}
.p6-icon-underlined:before {
  content: "\e9ed";
}
.p6-icon-unlocked:before {
  content: "\e9ee";
}
.p6-icon-uppercase:before {
  content: "\e9ef";
}
.p6-icon-user:before {
  content: "\e9f0";
}
.p6-icon-user-avatar:before {
  content: "\e9f1";
}
.p6-icon-user-unconfirmed:before {
  content: "\e9f2";
}
.p6-icon-vocabulary-list:before {
  content: "\e9f3";
}
.p6-icon-wishlist:before {
  content: "\e9f4";
}
.p6-icon-learning-ready:before {
  content: "\e9f5";
}
.p6-icon-smartphone:before {
  content: "\e9f6";
}
.p6-icon-cards_1:before {
  content: "\e9f7";
}
.p6-icon-data-processing:before {
  content: "\e9f8";
}
.p6-icon-adults:before {
  content: "\e9f9";
}
.p6-icon-parents:before {
  content: "\e9fa";
}
.p6-icon-students:before {
  content: "\e9fb";
}
.p6-icon-finsih:before {
  content: "\e900";
}
.p6-icon-activate:before {
  content: "\e901";
}
.p6-icon-add:before {
  content: "\e902";
}
.p6-icon-alarm-clock:before {
  content: "\e903";
}
.p6-icon-alert:before {
  content: "\e904";
}
.p6-icon-annotation:before {
  content: "\e905";
}
.p6-icon-apple:before {
  content: "\e906";
}
.p6-icon-arrow-left:before {
  content: "\e907";
}
.p6-icon-arrow-right:before {
  content: "\e908";
}
.p6-icon-arrows-right-left:before {
  content: "\e909";
}
.p6-icon-audio:before {
  content: "\e90a";
}
.p6-icon-back:before {
  content: "\e90b";
}
.p6-icon-back-to-storage:before {
  content: "\e90c";
}
.p6-icon-badge:before {
  content: "\e90d";
}
.p6-icon-bold:before {
  content: "\e90e";
}
.p6-icon-bulb:before {
  content: "\e90f";
}
.p6-icon-burger-menu:before {
  content: "\e910";
}
.p6-icon-calendar-check:before {
  content: "\e911";
}
.p6-icon-calendar-cross:before {
  content: "\e912";
}
.p6-icon-cam:before {
  content: "\e913";
}
.p6-icon-cards:before {
  content: "\e914";
}
.p6-icon-cart:before {
  content: "\e915";
}
.p6-icon-change-avatar:before {
  content: "\e916";
}
.p6-icon-change-folder:before {
  content: "\e917";
}
.p6-icon-check-answer:before {
  content: "\e918";
}
.p6-icon-checked:before {
  content: "\e91a";
}
.p6-icon-chevron-back:before {
  content: "\e91b";
}
.p6-icon-chevron-down:before {
  content: "\e91c";
}
.p6-icon-chevron-next:before {
  content: "\e91d";
}
.p6-icon-chevron-up:before {
  content: "\e91e";
}
.p6-icon-clipboard:before {
  content: "\e91f";
}
.p6-icon-clock:before {
  content: "\e920";
}
.p6-icon-close:before {
  content: "\e921";
}
.p6-icon-cloud:before {
  content: "\e922";
}
.p6-icon-counter:before {
  content: "\e923";
}
.p6-icon-cup:before {
  content: "\e924";
}
.p6-icon-database:before {
  content: "\e925";
}
.p6-icon-dataprotection:before {
  content: "\e926";
}
.p6-icon-design:before {
  content: "\e927";
}
.p6-icon-dictionary:before {
  content: "\e928";
}
.p6-icon-dot-menu:before {
  content: "\e92a";
}
.p6-icon-drag:before {
  content: "\e92b";
}
.p6-icon-edit:before {
  content: "\e92c";
}
.p6-icon-empty:before {
  content: "\e92d";
}
.p6-icon-eye:before {
  content: "\e92e";
}
.p6-icon-eye-closed1:before {
  content: "\e92f";
}
.p6-icon-family:before {
  content: "\e930";
}
.p6-icon-feedback:before {
  content: "\e931";
}
.p6-icon-feedback-alt:before {
  content: "\e932";
}
.p6-icon-filing:before {
  content: "\e933";
}
.p6-icon-filter:before {
  content: "\e934";
}
