@font-face {
    font-family: "phase6-icons";
    src: url("phase6-font/phase6-icons.ttf?l8ng2x") format("truetype"),
        url("phase6-font/phase6-icons.woff?l8ng2x") format("woff"),
        url("phase6-font/phase6-icons.svg?l8ng2x#phase6-icons") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.icon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "phase6-icons" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-special-character:before {
    content: "\e918";
}
.icon-angle_up:before {
    content: "\e912";
}
.icon-angle_down:before {
    content: "\e913";
}
.icon-info:before {
    content: "\e914";
}
.icon-close_x:before {
    content: "\e915";
}
.icon-angle_left:before {
    content: "\e916";
}
.icon-angle_right:before {
    content: "\e917";
}
.icon-icon_medal:before {
    content: "\e90f";
}
.icon-participants:before {
    content: "\e910";
}
.icon-icon_placement:before {
    content: "\e911";
}
.icon-stop_watch:before {
    content: "\e90e";
}
.icon-runing_time:before {
    content: "\e90d";
}
.icon-paint:before {
    content: "\e904";
}
.icon-mail:before {
    content: "\e905";
}
.icon-half_user_shield:before {
    content: "\e906";
}
.icon-user_shield:before {
    content: "\e907";
}
.icon-dictionary:before {
    content: "\e908";
}
.icon-star:before {
    content: "\e909";
}
.icon-checked_list:before {
    content: "\e90a";
}
.icon-link:before {
    content: "\e90b";
}
.icon-brightness:before {
    content: "\e90c";
}
.icon-bag:before {
    content: "\e903";
}
.icon-rocket:before {
    content: "\e900";
}
.icon-trophy:before {
    content: "\e901";
}
.icon-leader_board:before {
    content: "\e902";
}
