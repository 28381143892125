@-webkit-keyframes move-up {
    0% {
        top: 25px;
        opacity: 1;
    }

    100% {
        top: -50px;
        opacity: 0;
    }
}
@-moz-keyframes move-up {
    0% {
        top: 25px;
        opacity: 1;
    }

    100% {
        top: -50px;
        opacity: 0;
    }
}
@keyframes move-up {
    0% {
        top: 25px;
        opacity: 1;
    }

    100% {
        top: -50px;
        opacity: 0;
    }
}

.tile,
.tile .tile-inner {
    width: calc(25% - 15px);
    height: calc(25% - 11px);
    line-height: 107px;
}
.tile.tile-position-1-1 {
    -webkit-transform: translate(0px, 0px);
    -moz-transform: translate(0px, 0px);
    -ms-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
}
.tile.tile-position-1-2 {
    -webkit-transform: translate(0px, calc(100% + 15px));
    -moz-transform: translate(0px, calc(100% + 15px));
    -ms-transform: translateX(0px) translateY(100%) translateY(15px);
    transform: translate(0px, calc(100% + 15px));
}
.tile.tile-position-1-3 {
    -webkit-transform: translate(0px, calc(200% + 30px));
    -moz-transform: translate(0px, calc(200% + 30px));
    -ms-transform: translateX(0px) translateY(200%) translateY(30px);
    transform: translate(0px, calc(200% + 30px));
}
.tile.tile-position-1-4 {
    -webkit-transform: translate(0px, calc(300% + 45px));
    -moz-transform: translate(0px, calc(300% + 45px));
    -ms-transform: translateX(0px) translateY(300%) translateY(45px);
    transform: translate(0px, calc(300% + 45px));
}
.tile.tile-position-2-1 {
    -webkit-transform: translate(calc(100% + 15px), 0px);
    -moz-transform: translate(calc(100% + 15px), 0px);
    -ms-transform: translateX(100%) translateX(15px) translateY(0px);
    transform: translate(calc(100% + 15px), 0px);
}
.tile.tile-position-2-2 {
    -webkit-transform: translate(calc(100% + 15px), calc(100% + 15px));
    -moz-transform: translate(calc(100% + 15px), calc(100% + 15px));
    -ms-transform: translateX(100%) translateX(15px) translateY(100%) translateY(15px);
    transform: translate(calc(100% + 15px), calc(100% + 15px));
}
.tile.tile-position-2-3 {
    -webkit-transform: translate(calc(100% + 15px), calc(200% + 30px));
    -moz-transform: translate(calc(100% + 15px), calc(200% + 30px));
    -ms-transform: translateX(100%) translateX(15px) translateY(200%) translateY(30px);
    transform: translate(calc(100% + 15px), calc(200% + 30px));
}
.tile.tile-position-2-4 {
    -webkit-transform: translate(calc(100% + 15px), calc(300% + 45px));
    -moz-transform: translate(calc(100% + 15px), calc(300% + 45px));
    -ms-transform: translateX(100%) translateX(15px) translateY(300%) translateY(45px);
    transform: translate(calc(100% + 15px), calc(300% + 45px));
}
.tile.tile-position-3-1 {
    -webkit-transform: translate(calc(200% + 30px), 0px);
    -moz-transform: translate(calc(200% + 30px), 0px);
    -ms-transform: translateX(200%) translateX(30px) translateY(0px);
    transform: translate(calc(200% + 30px), 0px);
}
.tile.tile-position-3-2 {
    -webkit-transform: translate(calc(200% + 30px), calc(100% + 15px));
    -moz-transform: translate(calc(200% + 30px), calc(100% + 15px));
    -ms-transform: translateX(200%) translateX(30px) translateY(100%) translateY(15px);
    transform: translate(calc(200% + 30px), calc(100% + 15px));
}
.tile.tile-position-3-3 {
    -webkit-transform: translate(calc(200% + 30px), calc(200% + 30px));
    -moz-transform: translate(calc(200% + 30px), calc(200% + 30px));
    -ms-transform: translateX(200%) translateX(30px) translateY(200%) translateY(30px);
    transform: translate(calc(200% + 30px), calc(200% + 30px));
}
.tile.tile-position-3-4 {
    -webkit-transform: translate(calc(200% + 30px), calc(300% + 45px));
    -moz-transform: translate(calc(200% + 30px), calc(300% + 45px));
    -ms-transform: translateX(200%) translateX(30px) translateY(300%) translateY(45px);
    transform: translate(calc(200% + 30px), calc(300% + 45px));
}
.tile.tile-position-4-1 {
    -webkit-transform: translate(calc(300% + 45px), 0px);
    -moz-transform: translate(calc(300% + 45px), 0px);
    -ms-transform: translateX(300%) translateX(45px) translateY(0px);
    transform: translate(calc(300% + 45px), 0px);
}
.tile.tile-position-4-2 {
    -webkit-transform: translate(calc(300% + 45px), calc(100% + 15px));
    -moz-transform: translate(calc(300% + 45px), calc(100% + 15px));
    -ms-transform: translateX(300%) translateX(45px) translateY(100%) translateY(15px);
    transform: translate(calc(300% + 45px), calc(100% + 15px));
}
.tile.tile-position-4-3 {
    -webkit-transform: translate(calc(300% + 45px), calc(200% + 30px));
    -moz-transform: translate(calc(300% + 45px), calc(200% + 30px));
    -ms-transform: translateX(300%) translateX(45px) translateY(200%) translateY(30px);
    transform: translate(calc(300% + 45px), calc(200% + 30px));
}
.tile.tile-position-4-4 {
    -webkit-transform: translate(calc(300% + 45px), calc(300% + 45px));
    -moz-transform: translate(calc(300% + 45px), calc(300% + 45px));
    -ms-transform: translateX(300%) translateX(45px) translateY(300%) translateY(45px);
    transform: translate(calc(300% + 45px), calc(300% + 45px));
}

.tile {
    position: absolute;
    -webkit-transition: 100ms ease-in-out;
    -moz-transition: 100ms ease-in-out;
    transition: 100ms ease-in-out;
    -webkit-transition-property: -webkit-transform;
    -moz-transition-property: -moz-transform;
    transition-property: transform;
}
.tile .tile-inner {
    border-radius: 0.7rem;
    background: rgba(252, 124, 0, 0.9);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    font-size: 55px;
}
.tile.tile-2 .tile-inner {
    /* background: #eee4da; */
    box-shadow: 0 0 30px 10px rgba(243, 215, 116, 0), inset 0 0 0 1px rgba(255, 255, 255, 0);
}
.tile.tile-4 .tile-inner {
    background: #ede0c8;
    box-shadow: 0 0 30px 10px rgba(243, 215, 116, 0), inset 0 0 0 1px rgba(255, 255, 255, 0);
}
.tile.tile-8 .tile-inner {
    color: #f9f6f2;
    background: #f2b179;
}
.tile.tile-16 .tile-inner {
    color: #f9f6f2;
    background: #f59563;
}
.tile.tile-32 .tile-inner {
    color: #f9f6f2;
    background: #f67c5f;
}
.tile.tile-64 .tile-inner {
    color: #f9f6f2;
    background: #f65e3b;
}
.tile.tile-128 .tile-inner {
    color: #f9f6f2;
    background: #edcf72;
    box-shadow: 0 0 30px 10px rgba(243, 215, 116, 0.2381), inset 0 0 0 1px rgba(255, 255, 255, 0.14286);
    font-size: 45px;
}
@media screen and (max-width: 520px) {
    .tile.tile-128 .tile-inner {
        font-size: 25px;
    }
}
.tile.tile-256 .tile-inner {
    color: #f9f6f2;
    background: #edcc61;
    box-shadow: 0 0 30px 10px rgba(243, 215, 116, 0.31746), inset 0 0 0 1px rgba(255, 255, 255, 0.19048);
    font-size: 45px;
}
@media screen and (max-width: 520px) {
    .tile.tile-256 .tile-inner {
        font-size: 25px;
    }
}
.tile.tile-512 .tile-inner {
    color: #f9f6f2;
    background: #edc850;
    box-shadow: 0 0 30px 10px rgba(243, 215, 116, 0.39683), inset 0 0 0 1px rgba(255, 255, 255, 0.2381);
    font-size: 45px;
}
@media screen and (max-width: 520px) {
    .tile.tile-512 .tile-inner {
        font-size: 25px;
    }
}
.tile.tile-1024 .tile-inner {
    color: #f9f6f2;
    background: #edc53f;
    box-shadow: 0 0 30px 10px rgba(243, 215, 116, 0.47619), inset 0 0 0 1px rgba(255, 255, 255, 0.28571);
    font-size: 35px;
}
@media screen and (max-width: 520px) {
    .tile.tile-1024 .tile-inner {
        font-size: 15px;
    }
}
.tile.tile-2048 .tile-inner {
    color: #f9f6f2;
    background: #edc22e;
    box-shadow: 0 0 30px 10px rgba(243, 215, 116, 0.55556), inset 0 0 0 1px rgba(255, 255, 255, 0.33333);
    font-size: 35px;
}
@media screen and (max-width: 520px) {
    .tile.tile-2048 .tile-inner {
        font-size: 15px;
    }
}
.tile.tile-super .tile-inner {
    color: #f9f6f2;
    background: #3c3a32;
    font-size: 30px;
}
@media screen and (max-width: 520px) {
    .tile.tile-super .tile-inner {
        font-size: 10px;
    }
}

@-webkit-keyframes appear {
    0% {
        opacity: 0;
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0);
    }

    100% {
        opacity: 1;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }
}
@-moz-keyframes appear {
    0% {
        opacity: 0;
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0);
    }

    100% {
        opacity: 1;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }
}
@keyframes appear {
    0% {
        opacity: 0;
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0);
    }

    100% {
        opacity: 1;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }
}
.tile-new .tile-inner {
    -webkit-animation: appear 200ms ease 100ms;
    -moz-animation: appear 200ms ease 100ms;
    animation: appear 200ms ease 100ms;
    -webkit-animation-fill-mode: backwards;
    -moz-animation-fill-mode: backwards;
    animation-fill-mode: backwards;
}

@-webkit-keyframes pop {
    0% {
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0);
    }

    50% {
        -webkit-transform: scale(1.2);
        -moz-transform: scale(1.2);
        -ms-transform: scale(1.2);
        transform: scale(1.2);
    }

    100% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }
}
@-moz-keyframes pop {
    0% {
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0);
    }

    50% {
        -webkit-transform: scale(1.2);
        -moz-transform: scale(1.2);
        -ms-transform: scale(1.2);
        transform: scale(1.2);
    }

    100% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }
}
@keyframes pop {
    0% {
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0);
    }

    50% {
        -webkit-transform: scale(1.2);
        -moz-transform: scale(1.2);
        -ms-transform: scale(1.2);
        transform: scale(1.2);
    }

    100% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }
}
.tile-merged .tile-inner {
    z-index: 20;
    -webkit-animation: pop 200ms ease 100ms;
    -moz-animation: pop 200ms ease 100ms;
    animation: pop 200ms ease 100ms;
    -webkit-animation-fill-mode: backwards;
    -moz-animation-fill-mode: backwards;
    animation-fill-mode: backwards;
}
