html,
body,
#root {
    display: flex;
    align-self: stretch;
    justify-self: stretch;
    flex-grow: 1;
    height: 100%;
    overflow: hidden;
}

body {
    margin: 0;
    font-family: "ProximaNova", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f5f5f5;
}

* {
    box-sizing: border-box;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

p,
span {
    margin: 0;
    text-align: left;
}

button {
    font-family: "ProximaNova", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}
