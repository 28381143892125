@font-face {
    font-family: "ProximaNova";
    src: url("webFonts/proximanova-regular-webfont.woff2") format("woff2"),
        url("webFonts/proximanova-regular-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "ProximaNova";
    src: url("webFonts/proximanova-bold-webfont.woff2") format("woff2"),
        url("webFonts/proximanova-bold-webfont.woff") format("woff");
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: "ProximaNova";
    src: url("webFonts/proximanova-light-webfont.woff2") format("woff2"),
        url("webFonts/proximanova-light-webfont.woff") format("woff");
    font-weight: 200;
    font-style: normal;
}
